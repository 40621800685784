<template>
  <section class="section is-main-section">
    <OrgControlsList />
  </section>
</template>

<script>
import OrgControlsList from "../../components/OrgControlsList.vue";

export default {
  components: { OrgControlsList }
};
</script>
