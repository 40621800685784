<template>
  <section class="section is-main-section">
    <p>Locations</p>
    <div class="columns">
      <div class="column is-4-desktop">
        <button @click="addNode"><b-icon title="Add top level Location" icon="plus"></b-icon></button>
        <vue-tree-list
          @change-name="onChangeName"
          @delete-node="onDel"
          @add-node="onAddNode"
          :model="data"
          default-tree-node-name="New Location"
          default-leaf-node-name="New Wing"
          default-add-tree-node-title="sss"
          :addLeafNodeDisabled="true"
          :dragDisabled="true"
          v-bind:default-expanded="true"
        >
          <template v-slot:treeNodeIcon="slotProps">
            <b-icon :icon="getIcon(slotProps.model.locationType)" />
          </template>
          <template v-slot:addTreeNodeIcon="slotProps" title="">
            <ClickIcon
              v-if="slotProps.model.locationType === locationType.Grouping"
              :icon="getIcon(locationType.Grouping)"
              title="Add Location Group"
              @click="addLocation(slotProps.model, locationType.Grouping)"
            />
            <ClickIcon
              v-if="slotProps.model.locationType === locationType.Grouping"
              :icon="getIcon(locationType.MainLocation)"
              title="Add Designated Centre"
              @click="addLocation(slotProps.model, locationType.MainLocation)"
            />
            <ClickIcon
              v-if="slotProps.model.locationType >= locationType.MainLocation"
              :icon="getIcon(locationType.WingCorridor)"
              title="Add Sub Location"
              @click="addLocation(slotProps.model, locationType.WingCorridor)"
            />
          </template>

          <template v-slot:addLeafNodeIcon>&nbsp;</template>

          <template v-slot:editNodeIcon>
            <span class="icon"><b-icon icon="pencil" title="Edit Location"></b-icon></span>
          </template>
          <template v-slot:delNodeIcon>
            <span class="icon"><b-icon icon="delete" title="Delete Location"></b-icon></span>
          </template>
        </vue-tree-list>
      </div>
      <div class="column desktop"></div>
    </div>
  </section>
</template>

<script>
import { VueTreeList, Tree, TreeNode } from "vue-tree-list";
import { mapGetters } from "vuex";
import LocationType from "@/enums/locationType.js";
import ClickIcon from "@/components/ClickIcon";
import Icons from "@/mixins/iconsMixin.js";

export default {
  mixins: [Icons],
  components: { VueTreeList, ClickIcon },
  data() {
    return {
      isLoading: false,
      data: new Tree([]),
      locationType: LocationType.Enum
    };
  },
  computed: { ...mapGetters(["orgId"]) },
  created() {
    this.getData();
  },
  methods: {
    getIcon(t) {
      return this.Icons[LocationType.Icon[t]];
    },
    async getData() {
      if (this.isLoading) return;
      this.isLoading = true;
      await this.$http
        .get("allLocations/" + this.orgId)
        .then(r => {
          this.isLoading = false;
          if (r && r.data) {
            this.data = new Tree(r.data);
          }
        })
        .catch(e => {
          this.isLoading = false;
          this.$alerts.showErrorAlert(e, "Error loading locations");
        });
    },
    async onDel(node) {
      if (node.children && node.children.length) {
        this.$buefy.toast.open({ message: "Delete the sub-locations before trying to delte this location.", type: "is-danger" });
      } else {
        await this.deleteLocation(node.id);
        node.remove();
      }
    },

    onChangeName(params) {
      if (params.eventType === "blur") {
        this.editLocation({
          id: params.id,
          name: params.newName
        });
      }
    },

    async onAddNode(params) {
      var newLocation = {
        id: params.id,
        name: params.name,
        pid: params.pid,
        locationType: params.isLeaf ? LocationType.Enum.WingCorridor : LocationType.Enum.MainLocation
      };
      await this.saveNewLocation(newLocation);
      params.id = newLocation.id;
    },

    addNode() {
      var node = new TreeNode({ name: "New Location", isLeaf: false, dragDisabled: true });
      if (!this.data.children) this.data.children = [];
      this.data.addChildren(node);
      this.onAddNode(node);
    },

    async addLocation(model, t) {
      var newLocation = {
        id: 0,
        name: LocationType.DefaultTitle[t],
        pid: model.id,
        locationType: t
      };
      await this.saveNewLocation(newLocation);
      newLocation.dragDisabled = true;
      model.addChildren(new TreeNode(newLocation));
    },

    // getNewTree() {
    //   var vm = this;
    //   function _dfs(oldNode) {
    //     var newNode = {};

    //     for (var k in oldNode) {
    //       if (k !== "children" && k !== "parent") {
    //         newNode[k] = oldNode[k];
    //       }
    //     }

    //     if (oldNode.children && oldNode.children.length > 0) {
    //       newNode.children = [];
    //       for (var i = 0, len = oldNode.children.length; i < len; i++) {
    //         newNode.children.push(_dfs(oldNode.children[i]));
    //       }
    //     }
    //     return newNode;
    //   }
    //   vm.newTree = _dfs(vm.data);
    // },
    async saveNewLocation(newLocation) {
      await this.$http
        .post("allLocations/" + this.orgId, newLocation)
        .then(r => {
          if (r && r.data) {
            newLocation.id = r.data.locationId;
          }
        })
        .catch(e => {
          this.isLoading = false;
          this.$alerts.showErrorAlert(e, "Error adding location");
        });
    },
    editLocation(newLocation) {
      this.$http.put("allLocations/" + this.orgId, newLocation).catch(e => {
        this.$alerts.showErrorAlert(e, "Error updating location");
      });
    },
    async deleteLocation(locationId) {
      await this.$http.delete("allLocations/" + locationId).catch(e => {
        this.$alerts.showErrorAlert(e, "Error deleting location");
      });
    }
  }
};
</script>

<style scoped>
.icon {
  cursor: pointer;
}
</style>
